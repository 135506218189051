
.btn-primary, .btn-primary:focus,
.btn-secondary, .btn-secondary:focus {
    background-color: $blue;
    border-color: $blue;
}

.btn-primary:hover, .btn-secondary:hover {
    background-color: $light-blue;
    border-color: $light-blue;
}

.btn-primary {
    color: white !important;
}

.btn-danger {
    color: white !important;
}

.btn-primary-custom-vuetify {
    background-color: #3f51b5 !important;
    border-color: #3f51b5 !important
}
