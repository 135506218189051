#login {
    background-image: url("/storage/design/home_awb.jpg");
    background-size: cover;

    .login-form.row {
        height: calc(100vh - 90.109px);
    }

    .card {
        .card-header {
            background-color: white;
            border: none;
            text-transform: uppercase;
            width: 100%;

            .content {
                width: 100%;
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: $blue 2px solid;
                color: $blue;
            }
        }
    }

}
