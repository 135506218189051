// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

@import "layout";
@import "components/buttons";

@import "domains/login/general";
@import "components/navbar-top";
@import 'domains/vehicleClaims/general';


