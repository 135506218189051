
.no-margin-lr {
    margin-left: 0;
    margin-right: 0;
}

.input-design {
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.server-side-alert.alert {
    display: flex;
    justify-content: center;
}

.gap-12 {
    gap: 12px;
}

.capitalize{
    //text-transform: uppercase;
    select{
        text-transform: uppercase;
    }
}
