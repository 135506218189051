#vehicle-claims-view-list-container {
    .group:not(:first-child) {
        margin-top: 0.75rem;
    }

    .vehicle-list-container {
        margin-top: 1.5rem;
        min-height: 403px;

    }

    .vehicle-list-container.load-more {
        cursor: pointer;

        h3 {
            padding-top: 1rem;
        }

        i {
            font-size: 7rem;
        }
    }

    #card-scrolling-component {
        background-color: white;

        .card {
            border: 0;
        }
    }

    //.selected-filters-list {
    //
    //    > div:not(:last-child) {
    //        padding-right: 0.75rem;
    //    }
    //}

    #actions-to-answer {
        [class^='col'] {
            padding-left: 5px;
            padding-right: 5px;

            button {
                width: 100%;
            }
        }
    }

}


