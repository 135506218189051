#navbar-top {
    padding: 0;

    .navbar-brand {
        padding-top:0;
        padding-bottom: 0;
        .logo {
            width: 8rem;
        }
    }
}
